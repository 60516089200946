

















































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { IUser } from '../../shared/types'
import { AdministratorUserListRow } from '../components';
import { LazyImage, Checkbox } from '@components/UI';

@Component({
    components: { AdministratorUserListRow, LazyImage, Checkbox }
})
export default class AdminUserListPage extends Vue {
    @Action
    private adminLoadUserList: () => Promise<any>;

    @Getter
    private currentUserList;

    private showBanned: boolean = true;

    public mounted() {
        this.adminLoadUserList();
    }

    getAvatarOrDefault(user): string {
        if(user == null || user.profile == null || user.profile.avatar == '00000000-0000-0000-0000-000000000000')
            return 'https://i.imgur.com/Ga3MVQt.png';
        return `https://fhapi.bizzy.live/api/users/avatar/${user.profile.avatar}`;
        
    }

    getBio(user) {
        if(user != null && user.profile != null)
            return `${user.profile.bio}<br/>`;
        else
            return '';
    }

    getClass(user) {
        let cls: string = "";
        if(user.isVIP)
            cls = "row-vip";
        if(user.isAdmin)
            cls = "row-admin";
        if(user.isBanned)
            cls = "row-banned";

        return cls;
    }
}

