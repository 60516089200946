






















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { Spinner } from '@components/UI'
import { IPost } from '@shared/types';
import Ago from 's-ago';

@Component({
    components: { Spinner }
})
export default class AdministratorUserRow extends Vue {
    
    private loading = false;
    private error = '';

    @Prop()
    public image: IPost;

    @Action
    private adminApproveImage: (any) => Promise<any>;

    @Action
    private adminDeleteImage: (any) => Promise<any>;

    private async onApproveImage() {
        try {
            this.loading = true;

            const response = await this.adminApproveImage({ id: this.image.id });

            this.error = '';
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error.error || 'An Unknown Error Occured';
        }
    }

    private async onDeleteImage() {
        try {
            this.loading = true;

            const response = await this.adminDeleteImage({ id: this.image.id });

            this.error = '';
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error.error || 'An Unknown Error Occured';
        }
    }

    public get date() {
        return Ago(new Date(this.image.date));
    }

}

