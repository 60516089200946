

































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdministratorUserRow, AdministratorImageRow } from '../components';
import { Textfield, Spinner, LazyImage } from '@components/UI';
import { IUser } from '../../shared/types'
import { ConfirmationDialog } from '@components/Dialogs';
import { create } from 'vue-modal-dialogs';

interface ConfirmData {
    title?: string;
    content: string;
    confirmationText: string;
    isDangerousAction: boolean;
}

@Component({
    components: { Textfield, Spinner, LazyImage }
})
export default class AdminPendingPage extends Vue {
    @Prop()
    public user: string;

    private managedUser?: IUser;
    private muUsername: string = "";
    private muBanReason: string = "";
    private loading: boolean = false;
    private error: string = "";
    private success: string = "";

    @Action
    private adminGetUserFromName: (any) => Promise<any>;

    @Action
    private adminSetUserAdmin: (any) => Promise<any>;
    @Action
    private adminRemoveUserAdmin: (any) => Promise<any>;

    @Action
    private adminSetUserVip: (any) => Promise<any>;
    @Action
    private adminRemoveUserVip: (any) => Promise<any>;

    @Action
    private adminBanUser: (any) => Promise<any>;
    @Action
    private adminUnbanUser: (any) => Promise<any>;

    @Action
    private adminDeleteUser: (any) => Promise<any>;

    @Getter
    private currentUser: IUser;

    private async loadUser(user) {
        this.loading = true;

        try {
            const resp = await this.adminGetUserFromName({name: user});

            this.loading = false;
            this.managedUser = resp.user;
        } catch (error) {
            this.loading = false;
            this.error = error.error;
        }
    }

    public async mounted() {
        if(this.user != "")
            await this.loadUser(this.user);
    }

    private async muSubmit() {
        await this.loadUser(this.muUsername);
    }

    private showProfile(){
        let prof: any = this.managedUser;
        if(prof.profile.avatar === '00000000-0000-0000-0000-000000000000' && (prof.profile.bio == "" || prof.profile.bio == null))
            return false;

        return true;
    }

    private async giveAdmin() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminSetUserAdmin({id: this.managedUser.id});

                this.success = "The user has been given admin successfully.";
                this.loading = false;

                this.managedUser.isAdmin = true;
            } catch (error) {
                this.loading = false;
                this.error = error.error;
            }
        }
    }
    private async removeAdmin() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminRemoveUserAdmin({id: this.managedUser.id});

                this.success = "The user's admin status was removed successfully.";
                this.loading = false;

                this.managedUser.isAdmin = false;
            } catch (error) {
                this.loading = false;
                this.error = error.error;
            }
        }
    }

    private async giveVip() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminSetUserVip({id: this.managedUser.id});

                this.success = "The user has been given vip successfully.";
                this.loading = false;

                this.managedUser.isVIP = true;
            } catch (error) {
                this.loading = false;
                this.error = error.error;
            }
        }
    }
    private async removeVip() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminRemoveUserVip({id: this.managedUser.id});

                this.success = "The user's VIP status was removed successfully.";
                this.loading = false;

                this.managedUser.isVIP = false;
            } catch (error) {
                this.loading = false;
                this.error = error.error;
            }
        }
    }

    private async banUser() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminBanUser({uid: this.managedUser.id, reason: this.muBanReason});

                this.success = "The user has been banned successfully.";
                this.loading = false;

                this.managedUser.isBanned = true;
                this.managedUser.banReason = this.muBanReason;
            } catch (error) {
                this.loading = false;
                this.error = error.error;

                console.log("err", error);
            }
        }
    }
    private async unbanUser() {
        if(this.managedUser != undefined) {
            this.loading = true;
            this.error = "";
            this.success = "";

            try {
                const resp = await this.adminUnbanUser({id: this.managedUser.id});

                this.success = "The user has been unbanned successfully.";
                this.loading = false;

                this.managedUser.isBanned = false;
            } catch (error) {
                this.loading = false;
                this.error = error.error;
            }
        }
    }

    private confirmationDialog = create<ConfirmData>(ConfirmationDialog);
    private async deleteUser() {
        if(this.managedUser != undefined) {
            if (await this.confirmationDialog({ 
                content: "You're about to delete a user! This WILL delete all related content(images, polls, etc).", 
                confirmationText: 'Delete User', 
                isDangerousAction: true 
            })) 
            {
                this.loading = true;
                this.error = "";
                this.success = "";

                try {
                    const resp = await this.adminDeleteUser({id: this.managedUser.id});

                    this.success = "The user has been deleted successfully.";
                    this.loading = false;

                    this.managedUser = undefined;
                } catch (error) {
                    this.loading = false;
                    this.error = error.error;
                }
            }
        }
    }
}

