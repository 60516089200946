





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { IUser } from '@shared/types';
import { Spinner } from '@components/UI';

@Component({
    components: { Spinner }
})
export default class AdministratorUserRow extends Vue {
    
    private loading = false;
    private error = '';

    @Prop()
    public user: IUser;

    @Action
    private adminApproveUser: (any) => Promise<any>;

    @Action
    private adminDeleteUser: (any) => Promise<any>;


    private async onApproveUser() {
        try {
            this.loading = true;

            const response = await this.adminApproveUser({ id: this.user.id });

            this.error = '';
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error.error || 'An Unknown Error Occured';
        }
    }

    private async onDeleteUser() {
        try {
            this.loading = true;

            const response = await this.adminDeleteUser({ id: this.user.id });

            this.error = '';
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.error = error.error || 'An Unknown Error Occured';
        }
    }

}

