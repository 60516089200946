













import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { IUser } from '@shared/types';
import { Spinner } from '@components/UI';

@Component({
    components: { Spinner }
})
export default class AdministratorUserListRow extends Vue {
    
    @Prop()
    public user: IUser;

    @Prop()
    public showBanned: boolean;

    isVisible() {
        if(this.user.isBanned && !this.showBanned)
            return false;
        return true;
    }

    getClass() {
        let cls: string = "";
        if(this.user.isVIP)
            cls = "row-vip";
        if(this.user.isAdmin)
            cls = "row-admin";
        if(this.user.isBanned)
            cls = "row-banned";

        return cls;
    }
}

