







































































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { AdministratorUserRow, AdministratorImageRow } from './components';
import { AdminManageUserPage, AdminUserListPage } from './pages';
import { Icon } from '@components/UI'
import { IUser } from '../shared/types'

@Component({
    components: { AdministratorUserRow, AdministratorImageRow, AdminManageUserPage, AdminUserListPage, Icon }
})
export default class AdministratorDashboard extends Vue {
    
    @Action
    private adminLoadPendingImages: () => Promise<any>;

    @Action
    private adminLoadPendingUsers: () => Promise<any>;

    @Getter
    private currentlyPendingImages;

    @Getter
    private currentlyPendingUsers;

    private page: string = "pending";
    private muUsername: string = "";   

    public mounted() {
        this.adminLoadPendingImages();
        this.adminLoadPendingUsers();

        var npage = this.$route.params.page;
        if(npage == "pending" || npage == "manage-user" || npage == "user-list")
            this.page = npage;
        
        if(this.$route.params.username != "")
            this.muUsername = this.$route.params.username;
    }
    
}

